<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Prelab Quiz - Formation of a Solid Precipitate</h2>

      <p class="mb-2">a) What are the reactants involved in this experiment?</p>

      <p v-for="option in options" :key="'pt-1-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.input1" :value="option.value" class="pl-8 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mb-n3">
        b) Which of the following is the solid product that you will collect in this experiment?
      </p>

      <v-radio-group v-model="inputs.input2" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        c) Which of the following is the <b>soluble</b> product that you will dispose of?
      </p>

      <v-radio-group v-model="inputs.input3" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'HawkeyePrelabQuiz5',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
        input2: null,
        input3: null,
      },
      options: [
        {text: '$\\ce{SrCO3}$', value: 'a'},
        {text: '$\\ce{Na2CO3}$', value: 'b'},
        {text: '$\\ce{NaNO3}$', value: 'c'},
        {text: '$\\ce{Sr(NO3)2}$', value: 'd'},
        {text: '$\\ce{None of the above}$', value: 'e'},
      ],
    };
  },
};
</script>
<style scoped>
.v-input--checkbox {
  display: inline-block !important;
}
</style>
